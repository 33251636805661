/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--40 pt--80" name={"informace"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 pt--50" content={"Potvrdit účast"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--20" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 fs--24" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefonní číslo"},{"name":"Bližší informace","type":"textarea","required":true,"placeholder":"Máme vás čekat už v pátek, nebo přijedete až v sobotu na svatbu? "},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}